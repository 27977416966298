import React from "react"
import { useClickAway } from "react-use"
import { Close as CloseIcon } from "@styled-icons/material/Close"
import styled, { ThemeProvider } from "styled-components"
import { CSSTransition } from "react-transition-group"

import {
  InnerSection,
  UnstyledLink,
  Link,
  NavLink,
  ContactInformation,
  ContactKey,
  ContactValue,
} from "./shared"
import { menuTheme } from "./theme"
import { narrowNoBreakSpace } from "../util/typography"
import { ReactComponent as Logo } from "../images/Prause_Logo-17.svg"

const MenuNav = styled.nav`
  margin: 0 auto;
  padding: 0 1rem 0 0;

  display: grid;
  grid-template-columns: auto 2fr 1fr;
  gap: 1em;

  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

const scrollPosShrinkLogoPicture = 400
const shouldShrinkLogoPicture = y => y >= scrollPosShrinkLogoPicture

const shrunkHeaderHeight = "calc(max(4rem, 80px))"
const shrunkHeaderHeightNarrow = "4rem"

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.2em 1rem 1em 1rem;
  height: ${props =>
    shouldShrinkLogoPicture(props.$scrollY)
      ? shrunkHeaderHeight
      : props.theme.headerHeight};
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 960px) {
    height: ${props =>
      shouldShrinkLogoPicture(props.$scrollY)
        ? shrunkHeaderHeight
        : props.theme.headerHeightWide};
  }

  @media only screen and (max-width: 666px) {
    height: ${props =>
      shouldShrinkLogoPicture(props.$scrollY)
        ? shrunkHeaderHeightNarrow
        : props.theme.headerHeight};
  }

  transition: height 0.4s;
  transition-delay: 0.4s;
`

const CloseButton = styled.button.attrs(() => ({ type: "button" }))`
  margin: -1em -0.6em;
  padding: 1em 0.6em;
  font-weight: bold;
  cursor: pointer;
  border: none;

  &:hover,
  &:focus {
    color: ${props => props.theme.navLinkHoverColor};
  }
`

const CloseButtonIcon = styled(CloseIcon)`
  position: relative;
  bottom: 0.1em;
  left: -0.1em;
  height: 1.3em;
`

const LogoLink = styled(NavLink)`
  &.active {
    color: currentColor;
  }
`

const LogoWhite = styled(Logo)`
  margin: 1em 0;
  height: 162px;

  @media only screen and (min-width: 960px) {
    height: 12em;
  }
  @media only screen and (max-width: 480px) {
    margin: 1em 0 0 0;
  }
`

const PageLinks = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  list-style: none;
  margin: 0;
  padding: 0 1em 0 2em;
  margin-top: 5.3rem;
  font-size: 1.6rem;
  line-height: 1.3;
  height: 5.5em;

  @media only screen and (max-width: 960px) {
    padding: 0 1em 0 1em;
  }

  @media only screen and (max-width: 800px) {
    margin: 2rem 0 2rem 1rem;
    padding: 0;
    width: 80%;
  }

  @media only screen and (max-width: 480px) {
    margin: 0 0 2rem 0;
    width: 100%;
  }
`

const PageLink = styled(NavLink)`
  padding: 0 0.3em;
  font-weight: bold;
  text-decoration: none;
  white-space: nowrap;
  color: inherit;
  transition: color 0s;

  &::after {
    content: "▸";
    padding-left: 0.8em;
    font-size: 0.6em;
    position: relative;
    bottom: 0.15em;
  }
  &.active {
    color: ${props => props.theme.backgroundColor};
    background-color: ${props => props.theme.color};
    outline: 0.1em solid ${props => props.theme.color};
    padding-bottom: 0.1em;
  }
  @media only screen and (max-width: 480px) {
    margin-left: -0.3em;
  }
`

const Contact = styled.div`
  margin-top: 5.7rem;
  display: flex;
  flex-direction: column;
  gap: 1em; // TODO: browser support

  @media only screen and (max-width: 800px) {
    margin: 2rem 0 0 1rem;
  }
  @media only screen and (max-width: 480px) {
    margin: 0rem;
  }
`

const ContactHeading = styled.h4`
  margin: 0 0 0.5em 0;
`

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-bottom: 2em;
  z-index: 1000;
  max-height: 100vh;
  overflow-y: auto;
  color: ${props => props.theme.color};
  background-color: ${props => props.theme.backgroundColor};
  box-shadow: 0 0 6px 0 ${props => props.theme.shadowColor};

  &.menu-enter {
    // TODO: wrap in scaled container with hidden overflow?
    clip-path: inset(0 0 100% 0);
  }

  &.menu-enter-active {
    clip-path: inset(0 0 -2em 0);
    transition: clip-path 150ms ease-out;
  }

  &.menu-exit {
    clip-path: inset(0 0 -2em 0);
  }

  &.menu-exit-active {
    clip-path: inset(0 0 100% 0);
    transition: clip-path 150ms ease-in;
  }
`

const links = [
  "Magazin",
  "Leistungen",
  "Projekte",
  "Kontakt",
  "Leitbild",
  "Akademie",
  "Partner",
  "Team",
  "Jobs",
]

const Menu = ({ isOpen, closeMenu, scrollY }) => {
  const containerRef = React.useRef(null)
  useClickAway(containerRef, closeMenu)

  return (
    <ThemeProvider theme={menuTheme}>
      <CSSTransition
        in={isOpen}
        mountOnEnter
        unmountOnExit
        timeout={500}
        nodeRef={containerRef}
        classNames="menu"
      >
        <Container ref={containerRef}>
          <InnerSection>
            <MenuNav>
              <CloseButtonWrapper $scrollY={scrollY}>
                <CloseButton onClick={closeMenu}>
                  <CloseButtonIcon />
                  Menü
                </CloseButton>
              </CloseButtonWrapper>
              <LogoLink to="/" title="zur Startseite" onClick={closeMenu}>
                <LogoWhite />
              </LogoLink>
              <PageLinks data-columns="2">
                {links.map(link => (
                  <li key={link}>
                    <PageLink
                      to={
                        link === "Projekte"
                          ? `/#projekte`
                          : `/${link.toLowerCase()}`
                      }
                      onClick={closeMenu}
                    >
                      {link}
                    </PageLink>
                  </li>
                ))}
              </PageLinks>
              <Contact>
                <div>
                  <ContactHeading>Rufen Sie uns an</ContactHeading>
                  <UnstyledLink to="tel:+4922669018900">
                    02266{narrowNoBreakSpace}/{narrowNoBreakSpace}901890
                    {narrowNoBreakSpace}-{narrowNoBreakSpace}0
                  </UnstyledLink>
                </div>

                <div>
                  <ContactHeading>Schreiben Sie uns</ContactHeading>
                  <ContactInformation>
                    <Link to="mailto:info@holzbauplanung.de">
                      info@holzbauplanung.de
                    </Link>
                  </ContactInformation>
                </div>

                <div>
                  <ContactHeading>Bürozeiten</ContactHeading>
                  <ContactInformation>
                    <ContactKey>Mo.-Do.</ContactKey>
                    <ContactValue>08:00-16:00</ContactValue>

                    <ContactKey>Fr.</ContactKey>
                    <ContactValue>08:00-13:00</ContactValue>
                  </ContactInformation>
                </div>
              </Contact>
            </MenuNav>
          </InnerSection>
        </Container>
      </CSSTransition>
    </ThemeProvider>
  )
}

export default Menu
