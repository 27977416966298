import React from "react"
import styled, { css, ThemeProvider } from "styled-components"

import NewsletterForm from "./newsletter-form"
import { InnerSection, NavLink } from "./shared"
import { footerTheme } from "./theme"
import { ReactComponent as Logo } from "../images/Logo_Bildmarke_s.svg"
import { ReactComponent as FacebookLogoSvg } from "../images/facebook.svg"
import { ReactComponent as InstagramLogoSvg } from "../images/instagram.svg"
import { ReactComponent as TwitterLogoSvg } from "../images/twitter.svg"

const Container = styled.footer`
  /* position: relative;
  padding: 1.45rem 1.0875rem;
  font-weight: bold; */
  padding: 3em 0;
  color: ${props => props.theme.color};
  background-color: ${props => props.theme.backgroundColor};
`

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: 1em;
  @media only screen and (max-width: 666px) {
    grid-template-columns: 1fr;
    max-width: 80%;
  }
  @media only screen and (max-width: 480px) {
    max-width: 100%;
  }
`
const InnerRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: 1em;
`

const Column = styled.div`
  position: relative;
  /* &:not(:last-child) {
    padding-right: 1em;
  } */
`

const Stack = styled.div`
  display: flex;
  flex-direction: column;
`

const Grow = styled.div`
  flex: 1;
`

const FooterLogo = styled(Logo)`
  height: 3.4em;
  @media only screen and (min-width: 960px) {
    // TODO:
    /* height: 5.8vw; */
  }
`

const ColumnHeading = styled.h3`
  font-size: 1.6rem;
  line-height: 1;
  margin: 0 0 1em 0;
`

const Menu = styled.ul`
  padding-left: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const MenuItem = styled.li`
  margin-bottom: 0.2em;
`

const MenuLink = styled(NavLink)`
  display: inline-block;
  font-weight: bold;
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus {
    color: ${props => props.theme.navLinkHoverColor};
  }

  @media only screen and (max-width: 666px) {
    min-height: 30px;
  }
`

const logoCss = css`
  height: 2em;

  [data-color="foreground"] {
    fill: currentColor;
  }

  [data-color="background"] {
    fill: ${props => props.theme.backgroundColor || "#ffffff"};
  }
`

const FacebookLogo = styled(FacebookLogoSvg)`
  ${logoCss}
`

const InstagramLogo = styled(InstagramLogoSvg)`
  ${logoCss}
`

const TwitterLogo = styled(TwitterLogoSvg)`
  ${logoCss}
`

const HorizontalMenu = styled.ul`
  display: flex;
  padding-left: 0;
  gap: 1em;
  list-style: none;
`

const sitemapItems = [
  { title: "Start", path: "/" },
  null,
  { title: "Magazin", path: "/magazin" },
  { title: "Leistungen", path: "/leistungen" },
  { title: "Projekte", path: "/#projekte" },
  { title: "Kontakt", path: "/kontakt" },
  { title: "Leitbild", path: "/leitbild" },
  { title: "Akademie", path: "/akademie" },
  { title: "Partner", path: "/partner" },
  { title: "Team", path: "/team" },
  { title: "Jobs", path: "/jobs" },
  null,
  { title: "Datenschutz", path: "/impressum-datenschutz#datenschutz" },
  { title: "Impressum", path: "/impressum-datenschutz#impressum" },
]

const Footer = () => {
  return (
    <ThemeProvider theme={footerTheme}>
      <Container>
        <InnerSection>
          <Row>
            <Column>
              <InnerRow style={{ gridTemplateColumns: "1fr 3fr" }}>
                <Column>
                  <FooterLogo />
                </Column>
                <Column>
                  <ColumnHeading>Sitemap</ColumnHeading>
                  <Menu>
                    {sitemapItems.map((item, index) =>
                      item ? (
                        <MenuItem key={item.path}>
                          <MenuLink to={item.path}>{item.title}</MenuLink>
                        </MenuItem>
                      ) : (
                        // TODO:
                        <li key={index}>&nbsp;</li>
                      )
                    )}
                  </Menu>
                </Column>
              </InnerRow>
            </Column>
            <Column>
              <ColumnHeading>Social Media</ColumnHeading>
              <HorizontalMenu>
                <MenuItem>
                  <MenuLink
                    to="https://www.facebook.com/PrauseHolzbauplanung"
                    title="Facebook"
                  >
                    <FacebookLogo />
                  </MenuLink>
                </MenuItem>
                <MenuItem>
                  <MenuLink
                    to="https://www.instagram.com/prause_holzbauplanung/"
                    title="Instagram"
                  >
                    <InstagramLogo />
                  </MenuLink>
                </MenuItem>
                <MenuItem>
                  <MenuLink
                    to="https://twitter.com/holzbauplanung"
                    title="Twitter"
                  >
                    <TwitterLogo />
                  </MenuLink>
                </MenuItem>
              </HorizontalMenu>
            </Column>
            <Stack>
              <Grow>
                <ColumnHeading>Newsletter</ColumnHeading>
                <NewsletterForm />
              </Grow>
            </Stack>
          </Row>
        </InnerSection>
      </Container>
    </ThemeProvider>
  )
}

export default Footer
