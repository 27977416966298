import React from "react"
import styled, { ThemeProvider } from "styled-components"

import { NavLink, InnerSection } from "./shared"
import Nav from "./nav"
import { headerTheme } from "./theme"
import { ReactComponent as PrauseLogo } from "../images/Prause_Logo-17.svg"

const scrollPosHideLogoText = 100
const shouldHideLogoText = y => y >= scrollPosHideLogoText

const scrollPosShrinkLogoLink = 100
const shouldShrinkLogoLink = y => y >= scrollPosShrinkLogoLink

const scrollPosShowHeaderShadow = 100
const shouldShowHeaderShadow = y => y >= scrollPosShowHeaderShadow

const scrollPosShrinkLogoPicture = 400
const shouldShrinkLogoPicture = y => y >= scrollPosShrinkLogoPicture

const Wrapper = styled.div`
  height: ${props => props.theme.headerHeight};

  @media only screen and (min-width: 960px) {
    height: ${props => props.theme.headerHeightWide};
  }
`

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: ${props =>
    shouldShrinkLogoPicture(props.$scrollY)
      ? props.theme.shrunkHeaderHeight
      : props.theme.headerHeight};
  z-index: 100;

  @media only screen and (min-width: 960px) {
    height: ${props =>
      shouldShrinkLogoPicture(props.$scrollY)
        ? props.theme.shrunkHeaderHeight
        : props.theme.headerHeightWide};
  }

  @media only screen and (max-width: 666px) {
    height: ${props =>
      shouldShrinkLogoPicture(props.$scrollY)
        ? props.theme.shrunkHeaderHeightNarrow
        : props.theme.headerHeight};
  }

  transition: height 0.4s;
  transition-delay: 0.4s;

  /* box-shadow as pseudo-element for fast opacity transition */
  &::before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -2;
    opacity: ${props => (shouldShowHeaderShadow(props.$scrollY) ? 1 : 0)};
    box-shadow: 0 0 6px 0 ${props => props.theme.shadowColor};
    background-color: #ffffff;
    transition: opacity 0.2s;
    transition-delay: ${props =>
      shouldShowHeaderShadow(props.$scrollY) ? "0.2s" : "0"};
  }
`

const InnerContainer = styled(InnerSection)`
  height: 100%;

  /* box-shadow as pseudo-element for fast opacity transition */
  &::before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -2;
    opacity: ${props => (shouldShowHeaderShadow(props.$scrollY) ? 1 : 0)};
    background-color: ${props => props.theme.backgroundColor};
    transition: opacity 0.1s;
    transition-delay: ${props =>
      shouldShowHeaderShadow(props.$scrollY) ? "0" : "0.1s"};
  }
`

const LogoLink = styled(NavLink)`
  display: inline-block;
  // TODO: clip-path hides focus outline, but negative values are invalid
  clip-path: ${props =>
    props.$shrink ? "inset(0 60% 60% 0)" : "inset(0 0 0 0)"};
  opacity: ${props => (props.$hide ? 0 : 1)};
  transition: clip-path 0.1s, opacity 0.1s;
  transition-delay: ${props => (props.$shrink ? "1s" : "0s")}, 0.2s;

  &.active {
    color: currentColor;
  }
`

const logoHeight = `162px`
const logoHeightWide = `12em`

const shrunkLogoHeightNarrow = `7em`
const shrunkLogoHeight = logoHeight

const Logo = styled(PrauseLogo)`
  height: ${props => (props.$shrinkPicture ? shrunkLogoHeight : logoHeight)};
  margin: 1em 0;
  transition: height 0.4s;
  transition-delay: 0.4s;

  @media only screen and (min-width: 960px) {
    height: ${props =>
      props.$shrinkPicture ? shrunkLogoHeight : logoHeightWide};
  }

  @media only screen and (max-width: 666px) {
    height: ${props =>
      props.$shrinkPicture ? shrunkLogoHeightNarrow : logoHeight};
  }

  [data-role="text"] {
    opacity: ${props => (props.$hideText ? 0 : 1)};
    transition: opacity 0.4s;
  }
`

const Header = ({ openMenu, scrollY, enableScrollPosition }) => {
  return (
    <ThemeProvider theme={headerTheme}>
      <Wrapper>
        <Container $scrollY={scrollY}>
          <InnerContainer>
            <LogoLink
              to="/"
              title="zur Startseite"
              onClick={() => {
                // remove focus style from link
                // TODO: accessibility?
                document.activeElement.blur()
              }}
              $shrink={shouldShrinkLogoLink(scrollY)}
              $hide={!enableScrollPosition}
            >
              <Logo
                $hideText={shouldHideLogoText(scrollY)}
                $shrinkPicture={shouldShrinkLogoPicture(scrollY)}
              />
            </LogoLink>
            <Nav openMenu={openMenu} />
          </InnerContainer>
        </Container>
      </Wrapper>
    </ThemeProvider>
  )
}

export default Header
