import React from "react"
import { Menu as MenuIcon } from "@styled-icons/material/Menu"
import styled, { css } from "styled-components"

import { NavLink } from "./shared"

const NavWrapper = styled.nav`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
`

const NavItems = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 1.2em 1rem 1em 1rem;
  height: 100%;
  list-style: none;
  white-space: nowrap;
`

const NavItem = styled.li`
  margin-right: 1.8em;

  @media only screen and (max-width: 666px) {
    display: none;
  }
`

const MenuNavItem = styled.li``

const HeaderNavLinkCss = css`
  margin: -1em -0.6em;
  padding: 1em 0.6em;
  text-decoration: none;
  font-weight: bold;

  &:hover,
  &:focus {
    color: ${props => props.theme.navLinkHoverColor};
  }
`

const HeaderNavLink = styled(NavLink)`
  ${HeaderNavLinkCss}
`

const MenuButton = styled.button.attrs(() => ({ type: "button" }))`
  ${HeaderNavLinkCss}
  cursor: pointer;
  border: none;
`

const MenuButtonIcon = styled(MenuIcon)`
  position: relative;
  bottom: 0.1em;
  left: -0.1em;
  height: 1.3em;
`

const Nav = ({ openMenu }) => {
  return (
    <NavWrapper>
      <NavItems>
        <NavItem>
          <HeaderNavLink to="/leistungen">Leistungen</HeaderNavLink>
        </NavItem>
        <NavItem>
          <HeaderNavLink to="/#projekte">Projekte</HeaderNavLink>
        </NavItem>
        <NavItem>
          <HeaderNavLink to="/leitbild">Leitbild</HeaderNavLink>
        </NavItem>
        <MenuNavItem>
          <MenuButton
            onClick={openMenu}
            onMouseDown={() => {
              document.activeElement.blur()
            }}
          >
            <MenuButtonIcon />
            Menü
          </MenuButton>
        </MenuNavItem>
      </NavItems>
    </NavWrapper>
  )
}

export default Nav
