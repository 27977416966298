import React from "react"
import styled from "styled-components"

import { Link, Input } from "./shared"

const Form = styled.form``

const NewsletterInput = styled(Input)`
  margin-bottom: 0.6em;
  padding: 0.5em 0.4em 0.2em;
  width: 100%;
  height: 2.1em;
  line-height: 1.1;
`

const PrivacyNotice = styled.div`
  margin-bottom: 0.8em;
  display: -webkit-box;
  -webkit-line-clamp: ${props => (props.$show ? undefined : 2)};
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.8em;
  font-style: italic;
`

const SubmitButton = styled.button.attrs(() => ({
  type: "submit",
}))`
  width: 100%;
  height: 2.1em;
  border: 2px solid;
  background: none;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.18em 0.375em 0.25em 0.375em;

  &:hover,
  &:focus {
    cursor: pointer;
    color: ${props => props.theme.inputFocusOutlineColor};
  }
`

const ResultMessage = styled.div`
  margin-top: 1em;
  font-weight: bold;
`

// TODO: preserve state in case form is closed accidentally, or confirm closure?

// TODO: expand message
const successMessage = (
  <>Sie haben sich erfolgreich für den Newsletter angemeldet.</>
)

const failureMessage = (
  <>
    Ihre Anmeldung konnte leider nicht entgegengenommen werden. Bitte richten
    Sie sich direkt an{" "}
    <Link to="mailto:info@holzbauplanung.de">info@holzbauplanung.de</Link>.
  </>
)

const NewsletterForm = () => {
  const [name, setName] = React.useState("")
  const [emailAddress, setEmailAddress] = React.useState("")

  const [showPrivacyNotice, setShowPrivacyNotice] = React.useState(false)

  const [resultMessage, setResultMessage] = React.useState(null)

  const handleSubmit = event => {
    event.preventDefault()
    const form = event.target
    const formData = new FormData(form)
    formData.append("form-name", form.getAttribute("name"))
    // TODO: polyfill fetch, Object.fromEntries
    // TODO: show "sending" message
    fetch("/", {
      method: "POST",
      body: formData,
    })
      .then(response => {
        if (!response.ok) {
          return Promise.reject("non-2xx status response")
        }
      })
      .then(() => {
        setResultMessage(successMessage)
        window.setTimeout(() => {
          setResultMessage(null)
        }, 20_000)

        setName("")
        setEmailAddress("")
      })
      .catch(() => {
        setResultMessage(failureMessage)
      })
  }

  const formName = "Newsletter-Anmeldung"

  return (
    <Form name={formName} onSubmit={handleSubmit} data-netlify="true">
      <input type="hidden" name="form-name" value={formName} />
      <NewsletterInput
        type="text"
        name="Name"
        aria-label="Name"
        value={name}
        onChange={event => {
          setName(event.target.value)
        }}
        onFocus={() => {
          setShowPrivacyNotice(true)
        }}
        placeholder="Name"
      />
      <NewsletterInput
        type="email"
        name="E-Mail-Adresse"
        aria-label="E-Mail-Adresse"
        required
        value={emailAddress}
        onChange={event => {
          setEmailAddress(event.target.value)
        }}
        onFocus={() => {
          setShowPrivacyNotice(true)
        }}
        placeholder="E-Mail-Adresse"
      />
      <PrivacyNotice $show={showPrivacyNotice}>
        Ich willige ein, dass mich Prause Holzbauplanung per{" "}
        <span style={{ whiteSpace: "nowrap" }}>E-Mail</span> über die von ihr
        angebotenen Veröffentlichungen, Dienstleistungen und Veranstaltungen
        informiert. Meine Daten werden ausschließlich zu diesem Zweck genutzt.
        Der Versand des Newsletters erfolgt über den Anbieter{" "}
        <Link to="https://www.ionos.de/" target="_blank">
          IONOS
        </Link>
        . Ich kann die Einwilligung jederzeit per E-Mail an{" "}
        <Link to="mailto:info@holzbauplanung.de">info@holzbauplanung.de</Link>{" "}
        widerrufen. Es gilt die{" "}
        <Link to="/impressum-datenschutz#datenschutz">
          Datenschutzerklärung
        </Link>
        .
      </PrivacyNotice>
      <SubmitButton>Abonnieren</SubmitButton>

      <ResultMessage>{resultMessage}</ResultMessage>
    </Form>
  )
}

export default NewsletterForm
