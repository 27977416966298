import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

import {
  Link as DefaultLink,
  Paragraph as DefaultParagraph,
  OrderedList as DefaultOrderedList,
  UnorderedList as DefaultUnorderedList,
} from "../components/shared"
import { preventSingleWordLastLine } from "../util/typography"

const unwrapElement = (_node, children) => children

const fixContentfulImageUrl = url =>
  url.search(/\bimages\.ctfassets\.net\b/) ? `${url}?w=500&q=50` : url

export const correctUrls = urls =>
  urls.replace(/\/\/downloads\.ctfassets\.net\//g, "//images.ctfassets.net/")

export const RichText = ({
  node,
  linkComponent: Link = DefaultLink,
  paragraphComponent: Paragraph = DefaultParagraph,
  orderedListComponent: OrderedList = DefaultOrderedList,
  unorderedListComponent: UnorderedList = DefaultUnorderedList,
  h3Component: Heading3,
  imageComponent: Image,
  unwrapParagraphs = false,
}) => {
  // TODO: find a way to pass keys on to custom components
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => (
        <Link to={node.data.uri}>{children}</Link>
      ),
      // TODO: change field type instead of unwrapping?
      ...(unwrapParagraphs
        ? { [BLOCKS.PARAGRAPH]: unwrapElement }
        : Paragraph && {
            [BLOCKS.PARAGRAPH]: (_node, children) => (
              <Paragraph>{children}</Paragraph>
            ),
          }),
      ...(OrderedList && {
        [BLOCKS.OL_LIST]: (_, children) => (
          <OrderedList>{children}</OrderedList>
        ),
      }),
      ...(UnorderedList && {
        [BLOCKS.UL_LIST]: (_, children) => (
          <UnorderedList>{children}</UnorderedList>
        ),
      }),
      ...(Heading3 && {
        [BLOCKS.HEADING_3]: (_, children) => <Heading3>{children}</Heading3>,
      }),
      ...(Image && {
        [BLOCKS.EMBEDDED_ASSET]: ({ data }) => {
          const { url } = data.target.fields.file["de-DE"]
          const src = fixContentfulImageUrl(url)
          const alt = "" // TODO:
          return <Image src={src} alt={alt} />
        },
      }),
    },
    renderText: preventSingleWordLastLine,
  }

  return documentToReactComponents(node.json, options)
}
