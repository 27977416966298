import React from "react"
import styled from "styled-components"

import { Link, Input } from "./shared"
// import checkIcon from "../images/check.svg"

const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1em;
  margin-bottom: 3em;
  & > * {
    grid-column: span 3;
  }
`

// const CheckboxLabel = styled.label`
//   height: 2em;
//   display: flex;
//   align-items: center;
//   cursor: pointer;

//   * {
//     cursor: pointer;
//   }

//   & > :first-child {
//     flex: 1;
//     align-self: start;
//     margin-right: 1em;
//   }

//   & > :nth-child(2) {
//     flex: 6;
//   }
// `

// const Checkbox = styled.input.attrs(() => ({ type: "checkbox" }))`
//   position: relative;
//   padding: 0;
//   min-width: 1.6em;
//   height: 1.6em;
//   line-height: 0;
//   background-color: transparent;
//   border: 2px solid ${props => props.theme.inputBackgroundColor};
//   appearance: none;

//   &:checked::after {
//     content: " ";
//     position: absolute;
//     right: -50%;
//     bottom: 6%;
//     left: 12%;
//     padding-top: 100%;
//     background-image: url(${checkIcon});
//     background-size: 100%;
//     background-repeat: no-repeat;
//     border: 2px solid transparent;
//   }

//   &:hover,
//   &:focus {
//     border-color: ${props => props.theme.inputFocusOutlineColor};
//     outline-color: ${props => props.theme.inputFocusOutlineColor};
//   }
// `

const SubjectInput = styled(Input)`
  grid-column: 1 / -1;
  margin-top: 2em;
`

const BodyInput = styled(Input)`
  grid-column: 1 / -1;
  resize: vertical;
`

const Button = styled.button.attrs(() => ({ type: "button" }))`
  padding: 0.5em 0 0.3em 0;
  line-height: 1;
  font-weight: bold;
  border: 2px solid;

  &:hover,
  &:focus {
    cursor: pointer;
    color: ${props => props.theme.inputFocusOutlineColor};
  }
`

const PrivacyNotice = styled.div`
  grid-column: 1 / -1;
  font-size: 0.8em;
  font-style: italic;
`

const SubmitButton = styled(Button).attrs(() => ({ type: "submit" }))`
  font-size: 1.2em;
`

const ResultMessage = styled.div`
  grid-column: 1 / -1;
  font-weight: bold;
`

const FileList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const File = styled.li`
  &:not(:last-child) {
    margin-bottom: 1em;
  }
`

const FileInputWrapper = styled.label`
  flex: 1;
  display: flex;
  align-items: flex-start;

  & > *:not(:last-child) {
    margin-right: 1em;
  }
`

const TransparentInput = styled.input`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`

const FileName = styled(({ children, ...props }) => (
  <Input {...props} as="span" title={children}>
    {children ?? "Datei anhängen"}
  </Input>
))`
  flex: 1;
  padding: 0.25em 0.375em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${props =>
    props.children ? undefined : props.theme.inputPlaceholderColor};
  background-color: #ffffff;

  &:focus span {
    outline: 2px solid red;
  }
`

const FileInputButton = styled(Button)`
  padding: 0.25em 0.375em;
  min-width: 2.2em;
  min-height: 2.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
`

const RemoveButton = styled(FileInputButton).attrs(() => ({
  title: "Entfernen",
}))`
  &::before {
    content: "✕";
  }
`

const BrowseButton = styled(props => <FileInputButton {...props} as="span" />)`
  width: 33%;

  &::before {
    content: "Durchsuchen";
  }
`

const maxNumFiles = 5

const MultiFileInput = ({ files, dispatch }) => (
  <div style={{ gridColumn: "1/ -1" }}>
    <FileList>
      {[...files, ...(files.length < maxNumFiles ? [{}] : [])].map(file => (
        <File key={file.name || "empty"}>
          <FileInputWrapper>
            <TransparentInput
              type="file"
              onChange={event => {
                const newFile = event.target.files[0]
                dispatch({ type: "ADD_FILE", newFile })
                event.target.value = null
              }}
            />
            <FileName>{file.name}</FileName>
            {file.name ? (
              <RemoveButton
                onClick={event => {
                  event.stopPropagation()
                  dispatch({ type: "REMOVE_FILE", name: file.name })
                }}
              />
            ) : (
              <BrowseButton />
            )}
          </FileInputWrapper>
        </File>
      ))}
    </FileList>
  </div>
)

const encode = data => {
  const formData = new FormData()
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value)
  }
  return formData
}

// TODO: preserve state in case form is closed accidentally, or confirm closure?

// TODO: expand message
const successMessage = <>Ihre Nachricht wurde erfolgreich versandt.</>

const failureMessage = (
  <>
    Ihre Nachricht konnte leider nicht versandt werden. Bitte richten Sie sich
    direkt an{" "}
    <Link to="mailto:info@holzbauplanung.de">info@holzbauplanung.de</Link>.
  </>
)

const ContactForm = ({ intent, initialSubject = "" }) => {
  const [name, setName] = React.useState("")
  const [emailAddress, setEmailAddress] = React.useState("")
  // const [sendCopy, setSendCopany] = React.useState(true)
  const [company, setCompany] = React.useState("")
  const [subject, setSubject] = React.useState(initialSubject || "")
  const [body, setBody] = React.useState("")

  const [files, filesDispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case "ADD_FILE": {
        const newFileName = action.newFile.name
        return [
          ...state.filter(file => file.name !== newFileName),
          action.newFile,
        ]
      }
      case "REMOVE_FILE": {
        return state.filter(file => file.name !== action.name)
      }
      case "CLEAR_FILES": {
        return []
      }
      default: {
        return state
      }
    }
  }, [])

  const [resultMessage, setResultMessage] = React.useState(null)

  const handleSubmit = event => {
    event.preventDefault()
    const form = event.target
    // TODO: polyfill fetch, Object.fromEntries
    // TODO: show "sending" message in case upload takes a while
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...Object.fromEntries(new FormData(form).entries()),
        // TODO: more dynamic
        Anhang1: files[0],
        Anhang2: files[1],
        Anhang3: files[2],
        Anhang4: files[3],
        Anhang5: files[4],
      }),
    })
      .then(response => {
        if (!response.ok) {
          return Promise.reject("non-2xx status response")
        }
      })
      .then(() => {
        setResultMessage(successMessage)
        window.setTimeout(() => {
          setResultMessage(null)
        }, 20_000)

        setSubject("")
        setBody("")
        filesDispatch({ type: "CLEAR_FILES" })
      })
      .catch(() => {
        setResultMessage(failureMessage)
      })
  }

  const formName = "Kontaktformular"

  return (
    <Form name={formName} onSubmit={handleSubmit} data-netlify="true">
      <input type="hidden" name="form-name" value={formName} />
      <Input
        type="text"
        name="Name"
        aria-label="Name"
        required
        value={name}
        onChange={event => {
          setName(event.target.value)
        }}
        placeholder="Name"
      />

      <Input
        type="email"
        name="E-Mail-Adresse"
        aria-label="E-Mail-Adresse"
        required
        value={emailAddress}
        onChange={event => {
          setEmailAddress(event.target.value)
        }}
        placeholder="E-Mail-Adresse"
      />

      <Input
        type="text"
        name="Unternehmen"
        aria-label="Unternehmen"
        hidden={intent === "application"}
        value={company}
        onChange={event => {
          setCompany(event.target.value)
        }}
        placeholder="Unternehmen"
      />

      {/* TODO: implement, add name, consider spam issue */}
      {/* <CheckboxLabel>
        <Checkbox />
        Eine Kopie der Nachricht an mich
      </CheckboxLabel> */}

      <SubjectInput
        name="Betreff"
        aria-label="Betreff"
        value={subject}
        onChange={event => {
          setSubject(event.target.value)
        }}
        placeholder={
          intent === "application"
            ? "Bewerbung auf …"
            : "Projektname, Bauherr*in o.ä."
        }
      />

      <BodyInput
        as="textarea"
        name="Nachricht"
        aria-label="Nachricht"
        required
        value={body}
        onChange={event => {
          setBody(event.target.value)
        }}
        placeholder="Ihre Nachricht"
        rows={10}
      />

      {Array.from({ length: maxNumFiles }, (_, i) => (
        <Input type="file" name={`Anhang${i + 1}`} hidden key={i} />
      ))}
      <MultiFileInput files={files} dispatch={filesDispatch} />

      <PrivacyNotice>
        Die abgesendeten Daten werden nur zum Zweck der Bearbeitung Ihres
        Anliegens verarbeitet. Weitere Informationen in unserer{" "}
        <Link to="/impressum-datenschutz#datenschutz">
          Datenschutzerklärung
        </Link>
        .
      </PrivacyNotice>
      <SubmitButton>Senden</SubmitButton>

      <ResultMessage>{resultMessage}</ResultMessage>
    </Form>
  )
}

export default ContactForm
