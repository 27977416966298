import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import logoBanner from "../images/Prause_Logo-17_banner.png"
import relevantNormal from "../fonts/Relevant-Normal.woff"
import relevantItalic from "../fonts/Relevant-Italic.woff"
import relevantMedium from "../fonts/Relevant-Medium.woff"
import relevantBold from "../fonts/Relevant-Bold.woff"

function SEO({ description, lang, title }) {
  const { site, contentfulGeneralElements } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            siteUrl
          }
        }
        contentfulGeneralElements {
          metaDescription
        }
      }
    `
  )

  const siteDescription = contentfulGeneralElements.metaDescription
  const pageDescription = description || siteDescription

  const siteTitle = site.siteMetadata.title
  const pageTitle = title.includes(siteTitle)
    ? title
    : `${title} | ${siteTitle}`

  const logoBannerAbsolute = `${site.siteMetadata.siteUrl}${logoBanner}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
    >
      <meta name="description" content={pageDescription} />

      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={logoBannerAbsolute} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@holzbauplanung" />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#0f6da0" />
      <meta name="msapplication-TileColor" content="#0f6da0" />
      <meta name="theme-color" content="#0f6da0" />

      {[relevantNormal, relevantItalic, relevantMedium, relevantBold].map(
        (font, index) => (
          <link
            rel="preload"
            as="font"
            href={font}
            type="font/woff"
            crossOrigin="anonymous"
            key={index}
          />
        )
      )}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
