/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useWindowScroll } from "react-use"
import styled from "styled-components"
import "sanitize.css"
import "sanitize.css/forms.css"
// import "sanitize.css/typography.css"

import { Link } from "./shared"
import Header from "./header"
import Menu from "./menu"
import ContactSidebar from "./contact-sidebar"
import Footer from "./footer"
import { ModalContext } from "./modal-context"
import "./global.css"

const FullHeight = styled.div`
  margin: 0 auto;
  max-width: 100vw; // TODO: necessary?
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
`

const Main = styled.main`
  flex: 1;

  ${Link} {
    text-decoration-thickness: 2px;
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const { currentModal, showMenu, hideModal } = React.useContext(ModalContext)

  // TODO: check scroll position on page load
  const { y: trueY } = useWindowScroll()

  // only enable scroll position after first render to force re-render
  // so that the SSR version isn't stuck on unscrolled state
  const [enableScrollPosition, setEnableScrollPosition] = React.useState(false)
  React.useEffect(() => {
    setEnableScrollPosition(true)
  }, [])

  const scrollY = enableScrollPosition ? trueY : 0

  return (
    <FullHeight>
      <Menu
        isOpen={currentModal?.type === "menu"}
        closeMenu={hideModal}
        scrollY={scrollY}
      />
      <Header
        siteTitle={data.site.siteMetadata.title}
        openMenu={showMenu}
        scrollY={scrollY}
        enableScrollPosition={enableScrollPosition}
      />
      <Main>{children}</Main>
      <ContactSidebar
        isOpen={currentModal?.type === "contact"}
        closeSidebar={hideModal}
        intent={currentModal?.intent}
        initialSubject={currentModal?.initialSubject}
      />
      <Footer />
    </FullHeight>
  )
}

export default Layout
