export const noBreakSpace = "\u00a0"

export const narrowNoBreakSpace = "\u202f"

export const formatPhoneNumber = phoneNumber =>
  phoneNumber
    .replace(/^(0\d+)\s(?=\d)/, `$1${narrowNoBreakSpace}/${narrowNoBreakSpace}`)
    .replace(/\s?-\s?(\d+)$/, `${narrowNoBreakSpace}-${narrowNoBreakSpace}$1`)

export const formatAcademicTitle = title =>
  title
    .replace(/\b([BM]\.)\s?(Sc\.|Eng\.)/, `$1${narrowNoBreakSpace}$2`)
    .replace(/\b(Dipl\.-Ing\.)\s?(\(FH\))/, `$1${noBreakSpace}$2`)

export const preventSingleWordLastLine = text =>
  text.replace(/ (\S+)$/, (_, lastWord) => `${noBreakSpace}${lastWord}`)
