import React from "react"
import styled, { css } from "styled-components"
import { Link as GatsbyLink } from "gatsby"
import GatsbyImage from "gatsby-image"

import { ModalContext } from "./modal-context"
import { correctUrls } from "../api/contentful"

const SmartLink = ({
  children,
  to,
  rel,
  activeClassName,
  partiallyActive,
  preventsDefault,
  ...rest
}) => {
  if (rest.target === "_blank") {
    rel += " noreferrer"
  }

  if (
    to === "/" &&
    typeof children === "string" &&
    children.toLowerCase() === "start"
  ) {
    //  Google doesn't like links that say "start"
    rel += " nofollow"
  }

  const internal = /^\/(?!\/)/.test(to)
  if (!internal) {
    return (
      <a href={to} rel={rel} {...rest}>
        {children}
      </a>
    )
  }

  return (
    <GatsbyLink
      to={to}
      rel={rel}
      activeClassName={activeClassName}
      partiallyActive={partiallyActive}
      {...rest}
    >
      {children}
    </GatsbyLink>
  )
}

export const UnstyledLink = styled(SmartLink).attrs(() => ({
  activeClassName: "active",
}))`
  color: inherit;
  text-decoration: none;
`

const correctSrcUrls = imageProperties => ({
  ...imageProperties,
  src: correctUrls(imageProperties.src),
  srcSet: correctUrls(imageProperties.srcSet),
})

export const Image = ({ fluid, fixed, ...rest }) => {
  const correctedFluid = fluid && correctSrcUrls(fluid)
  const correctedFixed = fixed && correctSrcUrls(fixed)
  const correctedProps = {
    fluid: correctedFluid,
    fixed: correctedFixed,
    ...rest,
  }
  return <GatsbyImage {...correctedProps} />
}

export const InnerSection = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 1rem 0 1rem;
  max-width: ${props => props.theme.maxLayoutWidth};

  @media only screen and (max-width: 666px) {
    padding: 0 1rem 0 1rem;
  }
`

export const Section = styled(({ children, ...props }) => (
  <section {...props}>
    <InnerSection>{children}</InnerSection>
  </section>
))`
  padding: 2rem 0 5rem 0;
  color: ${props => props.theme.color};
  background-color: ${props => props.theme.backgroundColor};
  scroll-margin-top: 100px; /* TODO: won't work in Safari, IE and non-recent versions */

  @media only screen and (max-width: 666px) {
    padding: 2rem 0 3rem 0;
  }
`

export const SectionHeading = styled.h3`
  font-size: 2.5rem;
  line-height: 1;
  margin: 0.1em 0 0.4em 0;
  @media only screen and (max-width: 480px) {
    font-size: 1.9rem;
  }
`

export const SectionSubheading = styled.h4`
  font-size: 1.9rem;
  line-height: 1;
  margin: 0.6em 0;
  @media only screen and (max-width: 480px) {
    font-size: 1.6rem;
  }
`

export const ArticleSubheading = styled.h3`
  margin-top: 0.8em;
  margin-bottom: 0.4em;
`

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  hyphens: auto; /* TODO: Chromium only supports this on Android and macOS */

  & + & {
    text-indent: 1em;
  }
`

const listCss = css`
  margin: 0;
  padding: 0;
  hyphens: auto; /* TODO: Chromium only supports this on Android and macOS */

  @media only screen and (max-width: 666px) {
    padding-left: 1.25em;
  }

  &:not(:last-child) {
    margin-bottom: 0.5em;
  }

  & > li {
    margin-top: 0;
    margin-bottom: 0;
  }

  & > li + li {
    margin-top: 0.5em;
  }

  & & {
    padding-left: 1.25em;
  }
`

export const OrderedList = styled.ol`
  ${listCss}
`

export const UnorderedList = styled.ul`
  ${listCss}
  list-style-type: square;

  & > li::marker {
    font-size: 1.4em;
    color: ${props => props.theme.listItemColor}; // TODO: same for ol?
  }
`

export const BlockHeading = styled.h3`
  margin: 0.1em 0;
  font-size: 1.9rem;
  line-height: 1.1;
`

export const Pagehead = styled(InnerSection)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  margin-bottom: ${props => (props.bottomMargin ? `6em` : `0`)};
  padding-top: 0;
  padding-bottom: 0;
  min-height: 18em;
  text-align: right;

  @media only screen and (max-width: 960px) {
    margin: 58px 0 0 0;
  }

  @media only screen and (max-width: 666px) {
    margin: 88px 0 0 0;
  }
`

export const OpenerImage = styled(GatsbyImage)`
  min-width: 30em;
  min-height: 20em;
  object-fit: cover;
  object-position: center;

  @media only screen and (max-width: 666px) {
    min-width: unset;
    width: 80%;
    min-height: unset;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`

export const Narrow = styled.div`
  margin: 0 auto;
  /* padding: 0 1em; */
  max-width: 80%;
  @media only screen and (max-width: 800px) {
    max-width: 100%;
  }
`

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;

  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

const BreadcrumbsContainer = styled.div`
  font-size: 1rem;
  padding-top: 1em;
  padding-bottom: 1em;
`

const Breadcrumb = styled.span`
  font-style: italic;

  &:not(:first-child)::before {
    content: " —— ";
  }
`

export const Breadcrumbs = ({ nodes = [] }) => {
  if (nodes.some(node => node.path && !node.path.startsWith("/"))) {
    throw Error("Breadcrumb paths need to start with /")
  }

  const start = { title: "Start", path: "/" }

  return (
    <BreadcrumbsContainer>
      {[start, ...nodes].map(node => (
        <Breadcrumb key={node.path ?? "current"}>
          {node.path ? <Link to={node.path}>{node.title}</Link> : node.title}
        </Breadcrumb>
      ))}
    </BreadcrumbsContainer>
  )
}

export const Link = styled(UnstyledLink)`
  text-decoration: underline;
  text-decoration-color: ${props => props.theme.linkUnderlineColor};
  transition: text-decoration-color 0.3s;

  &:hover,
  &:focus {
    text-decoration-color: ${props => props.theme.linkUnderlineHoverColor};
  }
`

export const NavLink = styled(UnstyledLink)`
  transition: color 0.3s;

  &:hover,
  &:focus {
    color: ${props => props.theme.navLinkHoverColor};
  }

  &.active {
    color: ${props => props.theme.navLinkActiveColor};
  }
`

export const PageTitle = styled.h2`
  margin-top: 0.4em;
  margin-bottom: 0;
  margin-left: auto;
  width: 75%;
  font-size: 2.4rem;
  line-height: 1;
  font-weight: bold;

  @media only screen and (max-width: 800px) {
    width: 100%;
    padding-left: 1em;
  }
  @media only screen and (max-width: 480px) {
    font-size: 1.9rem;
  }

  ${Link}${Link} {
    text-decoration-thickness: 3px;
  }
`

export const CallToAction = styled(UnstyledLink)`
  display: inline-block;
  padding: 0.18em 2em 0.25em 2em;
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: bold;
  text-decoration: none;
  border: 2px solid;
  letter-spacing: 0.015em;
  text-align: center;
  @media only screen and (max-width: 480px) {
    font-size: 1rem;
  }

  &::after {
    content: "▸";
    margin-left: 0.4em;
    font-size: 0.9em;
  }

  &:hover,
  &:focus {
    color: ${props => props.theme.callToActionHoverColor};
    transition: color 0.3s;
  }
`

export const CallToActionVariant = styled(UnstyledLink)`
  display: inline-block;
  padding: 0.25em 2em 0.25em 2em;
  font-weight: 800;
  font-size: 1.1rem;
  text-decoration: none;
  color: ${props => props.theme.callToActionVariantColor};
  background-color: ${props => props.theme.callToActionVariantBackgroundColor};

  &::after {
    content: "▸";
    margin-left: 0.3em;
    font-size: 0.9em;
  }

  &:hover,
  &:focus {
    color: ${props => props.theme.callToActionVariantHoverColor};
    background-color: ${props =>
      props.theme.callToActionVariantHoverBackgroundColor};
  }
`

export const ContactContainer = styled.div`
  font-size: 1.25rem; /* TODO: */
  line-height: 1.2em;
  color: ${props => props.theme.color};
  background-color: ${props => props.theme.backgroundColor};

  @media only screen and (min-width: 960px) {
    font-size: 1rem;
  }
`

export const ContactInformation = styled.dl`
  display: grid;
  grid-template-columns: auto 1fr;
  margin: 0;
`

export const ContactKey = styled.dt`
  padding-right: 1em;
  line-height: 1.45em;
`

export const ContactValue = styled.dd`
  margin: 0;
  line-height: 1.45em;
`

export const Input = styled.input`
  color: ${props => props.theme.inputColor};
  background-color: ${props => props.theme.inputBackgroundColor};
  min-height: 2em;
  border: 2px solid transparent;
  border-radius: 0;

  /* &:hover, */
  &:focus {
    border-color: ${props => props.theme.inputFocusOutlineColor};
    outline-color: ${props => props.theme.inputFocusOutlineColor};
  }

  &::placeholder {
    color: ${props => props.theme.inputPlaceholderColor};
  }
`

export const PageheadCallToAction = styled(CallToAction)`
  margin-top: 0.6em;
`

export const InquiryCallToAction = props => {
  const { showInquiryForm } = React.useContext(ModalContext)

  return (
    <PageheadCallToAction
      to="/kontakt"
      onClick={event => {
        event.preventDefault()
        showInquiryForm()
      }}
      preventsDefault
      {...props}
    />
  )
}

export const ApplicationCallToAction = ({ initialSubject, ...props }) => {
  const { showApplicationForm } = React.useContext(ModalContext)

  return (
    <CallToAction
      to="mailto:jobs@holzbauplanung.de"
      onClick={event => {
        event.preventDefault()
        showApplicationForm(initialSubject)
      }}
      preventsDefault
      {...props}
    />
  )
}
