import React from "react"
import { useClickAway } from "react-use"
import { Close as CloseIcon } from "@styled-icons/material/Close"
import styled, { ThemeProvider } from "styled-components"
import { CSSTransition } from "react-transition-group"

import ContactForm from "./contact-form"
import { contactTheme } from "./theme"

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 30em;
  z-index: 100;
  overflow-y: auto;

  padding: 1em;
  box-shadow: 0 0 6px 0 ${props => props.theme.shadowColor};
  color: ${props => props.theme.color};
  background-color: ${props => props.theme.backgroundColor};

  &.sidebar-enter {
    clip-path: inset(0 0 0 100%);
  }

  &.sidebar-enter-active {
    clip-path: inset(0 0 0 -2em);
    transition: clip-path 150ms ease-out;
  }

  &.sidebar-exit {
    clip-path: inset(0 0 0 -2em);
  }

  &.sidebar-exit-active {
    clip-path: inset(0 0 0 100%);
    transition: clip-path 150ms ease-in;
  }

  @media only screen and (max-width: 666px) {
    width: 90vw;
  }
  @media only screen and (max-width: 480px) {
    width: 100vw;
  }
`

const CloseButton = styled.button.attrs(() => ({
  type: "button",
}))`
  position: fixed;
  top: 1rem;
  right: 0.4rem;
  border: none;

  &:hover,
  &:focus {
    cursor: pointer;
    color: ${props => props.theme.navLinkHoverColor};
  }
`

const CloseButtonIcon = styled(CloseIcon)`
  height: 1.4em;
`

const Heading = styled.h2`
  margin-top: 2em;
  font-size: 2rem;
  line-height: 1.1;
`

const ContactSidebar = ({ isOpen, closeSidebar, intent, initialSubject }) => {
  const containerRef = React.useRef(null)
  useClickAway(containerRef, closeSidebar)

  return (
    <ThemeProvider theme={contactTheme}>
      <CSSTransition
        in={isOpen}
        mountOnEnter
        unmountOnExit
        timeout={500}
        nodeRef={containerRef}
        classNames="sidebar"
      >
        <Container ref={containerRef}>
          <CloseButton onClick={closeSidebar}>
            <CloseButtonIcon />
          </CloseButton>
          <Heading>
            {intent === "application"
              ? "Wir freuen uns über deine Bewerbung und melden uns umgehend bei Dir."
              : "Wir freuen uns, dass Sie an einer Zusammenarbeit interessiert sind. Schreiben Sie uns, wir melden uns schnellstmöglich zurück."}
          </Heading>
          <ContactForm intent={intent} initialSubject={initialSubject} />
        </Container>
      </CSSTransition>
    </ThemeProvider>
  )
}

export default ContactSidebar
